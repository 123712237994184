import React, { RefObject, useState, useEffect } from "react";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { Document, Page, pdfjs } from "react-pdf";

import termsPDF from "../docs/terms.pdf";
import {
    Footer,
    Header
  } from "../components";  

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      container: {
        backgroundColor: "#FFF",
        display: "flex",
        flexGrow: 1,
      },
      content: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
        margin: "20px 0px"
      },
      paper: {
        width: "80%",
        marginTop: 50,
        borderRadius: 15,
        backgroundColor: "#fff",
        padding: theme.spacing(4),
        "& > h4": {
          fontSize: "2rem",
          marginBottom: theme.spacing(5),
        },
        "& > h3": {
          fontSize: "2.5rem",
          marginBottom: theme.spacing(3),
        },
        "& > p": {
          marginBottom: theme.spacing(6),
        },
      },
    })
  );

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Terms = () => {
  const classes = useStyles();
  const [numPages, setNumPages] = useState(0);
  const [docWidth, setWidth] = useState(300);
  const docRef: RefObject<HTMLDivElement> = React.createRef();

  useEffect(() => {
    // Update the document width
    let docWidth = docRef.current?.offsetWidth
      ? docRef.current?.offsetWidth
      : 500;
    setWidth(docWidth);
  }, [docRef, docWidth]);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  return (
    <>
      <div className={classes.container}>
        <Header />

        <div className={classes.content}>
          <div className={classes.paper}>
            <Typography align="center" variant="h3">Terms and conditions</Typography>

            <Document
          file={termsPDF}
          inputRef={docRef}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              width={docWidth}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ))}
        </Document>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
